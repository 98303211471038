import React from "react";
import { useEffect, useState } from "react";
import { UrlApi } from "../../../../../services/apirest";
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ContactsIcon from "@mui/icons-material/Contacts";
import PushPinIcon from "@mui/icons-material/PushPin";
import InputAdornment from "@mui/material/InputAdornment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import BadgeIcon from "@mui/icons-material/Badge";
import Notify from "../../../../Notify";
import UbicarDomMapaAlta from "./UbicarDomMapaAlta";
import AddPrecioAlta from "../../../../lista_precios/granel/preciosEspeciales/AddPrecioAlta";

function DialogEdicion(props) {
  const [direccionProp, setDireccionProp] = useState(props.direccion);
  const [alta, setAlta] = useState(false);
  const [dialog, setDialog] = useState(true);
  const [checked, setChecked] = useState(
    props.direccion.precio_especial === 1 ? true : false
  );
  const [precioEspecial, setPrecioEspecial] = useState(0);
  const [datosformulario, setDatosformulario] = useState(props.direccion);

  useEffect(() => {
    props.direccion &&
      setDatosformulario({
        ...props.direccion,
        telefono1: props.direccion.telefono1
          ? props.direccion.telefono1.replace(/^(\+?549)?(\d{10})$/, "$2")
          : "",
        telefono2: props.direccion.telefono2
          ? props.direccion.telefono2.replace(/^(\+?549)?(\d{10})$/, "$2")
          : "",
        telefono3: props.direccion.telefono3
          ? props.direccion.telefono3.replace(/^(\+?549)?(\d{10})$/, "$2")
          : "",
      });
  }, [props.direccion]);

  const [clientes, setClientes] = useState(null);
  const [localidades, setLocalidades] = useState(null);
  const [rutas, setRutas] = useState(null);
  const [productos, setProductos] = useState(null);
  const [provincias, setProvincias] = useState(null);
  const [sucursales, setSucursales] = useState(null);
  const [empresas, setEmpresas] = useState(null);
  const [notificacion, setNotificacion] = useState(false); //define si se muestra o no una notificación (snackbar)
  const [openSnack, setOpenSnack] = React.useState(false); //abre y cierra snack
  const [mensaje, setMensaje] = useState(""); //mensaje q mostrará la notif
  const [error, setError] = useState(null); //guardará el error que lance la api (si existe)
  const [mensajeAlerta, setMensajeAlerta] = useState(""); //mensaje que contendrá el componente "alerta"
  const [tipo_moneda, settipo_moneda] = useState(null);
  const [disBtn, setDisBtn] = useState(false);

  const almacenar = (e) => {
    setDatosformulario({
      ...datosformulario,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
    });
  };

  const [codArg, setCodArg] = useState("");
  const getCodArg = () => {
    fetch(UrlApi + "/desplegables/codArg")
      .then((data) => data.json())
      .then((data) => setCodArg(data[0].valor));
  };

  const obtenerClientes = () => {
    fetch(UrlApi + "/clientes") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setClientes(data)); //seteo Sucursales
  };

  const obtenerLocalidades = () => {
    fetch(UrlApi + "/localidades/prov/" + datosformulario.id_provincia)
      .then((data) => data.json())
      .then((data) => setLocalidades(data));
  };

  const obtenerSuc = () => {
    fetch(UrlApi + "/sucursales") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setSucursales(data)); //seteo Sucursales
  };

  const obtenerSucTipoPlanta = () => {
    fetch(UrlApi + "/sucursales/tipoPlanta") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setSucursales(data)); //seteo Sucursales
  };

  const obtenerRutas = () => {
    fetch(UrlApi + "/rutas/suc/" + datosformulario.id_sucursal) //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setRutas(data)); //seteo Sucursales
  };

  const obtenerProvincias = () => {
    fetch(UrlApi + "/provincias") //fetch con la urp de api q esta en services/apirest + la ruta
      .then((data) => data.json()) //mando los datos que recibo en json
      .then((data) => setProvincias(data)); //seteo Sucursales
  };

  const obtenerProductos = () => {
    fetch(UrlApi + "/productos")
      .then((data) => data.json())
      .then((data) => setProductos(data));
  };

  const obtenerEmpresas = () => {
    fetch(UrlApi + "/empresas")
      .then((data) => data.json())
      .then((data) => setEmpresas(data));
  };

  const obtenertipoMoneda = () => {
    fetch(UrlApi + "/tipo_moneda_afip")
      .then((data) => data.json())
      .then((data) => settipo_moneda(data));
  };

  useEffect(() => {
    if (props.tipoCliente === 1) {
      obtenerSucTipoPlanta();
    } else {
      obtenerSuc();
    }
  }, [props.tipoCliente]);

  useEffect(() => {
    obtenerClientes();
    obtenerProvincias();
    obtenerProductos();
    obtenerEmpresas();
    obtenertipoMoneda();
    getCodArg();
  }, []);

  useEffect(() => {
    obtenerRutas();
  }, [datosformulario.id_sucursal]);

  useEffect(() => {
    obtenerLocalidades();
    if (!(props.direccion.id_provincia === datosformulario.id_provincia)) {
      datosformulario.id_localidad = "";
    }
  }, [datosformulario.id_provincia]);

  //Funcion para guardar la direccion de entrega
  async function guardarDireccion(e) {
    setDisBtn(true);
    e.preventDefault();
    const response = await fetch(
      UrlApi + "/direccionesentrega/" + datosformulario.id,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          direccion_entrega: datosformulario.direccion_entrega,
          contacto: datosformulario.contacto,
          telefono1: datosformulario.telefono1
            ? codArg + datosformulario.telefono1
            : null,
          telefono2: datosformulario.telefono2
            ? codArg + datosformulario.telefono2
            : null,
          telefono3: datosformulario.telefono3
            ? codArg + datosformulario.telefono3
            : null,
          mails: datosformulario.mails,
          latitud: datosformulario.latitud,
          longitud: datosformulario.longitud,
          id_cliente: datosformulario.id_cliente,
          id_localidad: datosformulario.id_localidad,
          id_ruta:
            datosformulario.id_ruta === "" ? null : datosformulario.id_ruta,
          id_provincia: datosformulario.id_provincia,
          usuario: localStorage.getItem("user"),
          nombre_direccion: datosformulario.nombre_direccion,
          id_sucursal: datosformulario.id_sucursal,
          id_producto:
            datosformulario.id_producto === ""
              ? null
              : datosformulario.id_producto,
          precio_especial: precioEspecial,
          id_empresa: datosformulario.id_empresa,
          id_tipomoneda: datosformulario.id_tipomoneda,
        }),
      }
    );
    if (response.ok === false) {
      const error = response && response.json();
      setError(error);
      setMensajeAlerta("Inconvenientes con la API. No se pudo guardar. ");
      props.setOpen(false);
    } else {
      try {
        const json = await response.json();
        if (json.message) {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje(
            json.message === "OK" ? "Guardado" : "Guardado. Actualizar RTA Api"
          );
          props.actualizar();
        } else if (json.sqlMessage) {
          json && setError(json);
          alert(
            "NO SE PUDO ACTUALIZAR LA DIRECCIÓN DE ENTREGA. SQL erróneo (" +
              json.code +
              " Errno: " +
              json.errno +
              " )"
          );
          props.setOpen(false);
        } else {
          alert("ERROR DESCONOCIDO: " + error);
        }
      } catch (error) {
        if (error.toString().includes("is not valid JSON")) {
          alert("Edición Guardada.");
          props.actualizar();
        } else {
          setNotificacion(true);
          setOpenSnack(true);
          setMensaje("Guardado. Actualizar RTA API a Json.");
          props.setOpen(false);
        }
      }
    }
  }

  // CHECK PARA PRECIO ESPECIAL
  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (checked === true) {
      setPrecioEspecial(1);
    } else if (checked === false) {
      setPrecioEspecial(0);
    }
  }, [checked]);

  const handleClose = () => {
    setAlta(false);
    setDialog(true);
  };

  return (
    <div>
      {notificacion ? (
        <Notify
          mensaje={mensaje}
          open={openSnack}
          color={"#D4EFDF"}
          severity={"success"}
          handleClose={() => props.setOpen(false)}
        />
      ) : null}
      {dialog ? (
        <Dialog
          maxWidth="xl"
          open={props.open}
          onClose={() => props.setOpen(false)}
          style={{ zIndex: 1 }}
        >
          <Box justifyContent="center" backgroundColor="#1F618D">
            <DialogTitle>
              <Typography variant="h6" align="center" color="#ffffff">
                Editar Dirección {props.direccion.direccion_entrega}
              </Typography>
            </DialogTitle>
          </Box>

          <DialogContent>
            <form>
              {/* <Grid container spacing={1.5} justifyContent="space-between"> */}
              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={7}>
                  <Grid container direction="row" spacing={1.5}>
                    <Grid item xs={3}>
                      <FormControl size="small" fullWidth focused required>
                        <InputLabel>Provincia</InputLabel>
                        <Select
                          name="id_provincia"
                          labelId="id_provincia"
                          value={datosformulario.id_provincia}
                          label="Provincia"
                          onChange={almacenar}
                        >
                          {provincias
                            ? provincias.map((elemento) => (
                                <MenuItem key={elemento.id} value={elemento.id}>
                                  {elemento.provincia}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl size="small" fullWidth focused required>
                        <InputLabel>Localidad</InputLabel>
                        <Select
                          focused
                          required
                          name="id_localidad"
                          label="Localidad"
                          value={datosformulario.id_localidad}
                          onChange={almacenar}
                        >
                          {localidades
                            ? localidades.map((elemento) => (
                                <MenuItem key={elemento.id} value={elemento.id}>
                                  {elemento.localidad}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl size="small" fullWidth focused required>
                        <InputLabel>Sucursal</InputLabel>
                        <Select
                          focused
                          required
                          name="id_sucursal"
                          label="Sucursal"
                          value={datosformulario.id_sucursal}
                          onChange={almacenar}
                        >
                          {sucursales
                            ? sucursales.map((elemento) => (
                                <MenuItem key={elemento.id} value={elemento.id}>
                                  {elemento.nombre}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl size="small" fullWidth focused required>
                        <InputLabel>Empresa</InputLabel>
                        <Select
                          focused
                          required
                          name="id_empresa"
                          label="Empresa"
                          value={datosformulario.id_empresa}
                          onChange={almacenar}
                        >
                          {empresas
                            ? empresas.map((elemento) => (
                                <MenuItem
                                  key={elemento.idempresa}
                                  value={elemento.idempresa}
                                >
                                  {elemento.empresa}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Ejemplo: Casa Central"
                        type="text"
                        required
                        focused
                        name="nombre_direccion"
                        label="Lugar de entrega"
                        color="primary"
                        onChange={almacenar}
                        value={datosformulario.nombre_direccion}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BadgeIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Direccion de entrega"
                        type="text"
                        required
                        focused
                        name="direccion_entrega"
                        id="direccion_entrega"
                        label="Direccion de entrega"
                        color="primary"
                        onChange={almacenar}
                        value={
                          datosformulario.direccion_entrega &&
                          datosformulario.direccion_entrega.replace(
                            /\b\w/g,
                            (l) => l.toUpperCase()
                          )
                        }
                        inputProps={{ maxLength: 200 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LocationOnIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Sr .. "
                        type="text"
                        focused
                        name="contacto"
                        label="Contacto"
                        color="primary"
                        onChange={almacenar}
                        value={datosformulario.contacto}
                        inputProps={{ maxLength: 200 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <ContactsIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="ejemplo@mail.com"
                        type="email"
                        focused
                        name="mails"
                        id="mails"
                        label="Mails"
                        color="primary"
                        onChange={almacenar}
                        value={datosformulario.mails}
                        inputProps={{ maxLength: 300 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <ContactMailIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {tipo_moneda && (
                        <FormControl
                          size="small"
                          fullWidth
                          focused
                          required
                          color="primary"
                        >
                          <InputLabel>Tipo moneda</InputLabel>
                          <Select
                            name="id_tipomoneda"
                            labelId="id_tipomoneda"
                            value={datosformulario.id_tipomoneda}
                            label="Tipo moneda"
                            onChange={almacenar}
                          >
                            {tipo_moneda
                              ? tipo_moneda.map((elemento) => (
                                  <MenuItem
                                    key={elemento.idtipo_moneda_afip}
                                    value={elemento.idtipo_moneda_afip}
                                  >
                                    {elemento.moneda}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        type="number"
                        focused
                        name="telefono1"
                        label="Telefono 1"
                        color="primary"
                        onChange={almacenar}
                        value={datosformulario.telefono1}
                        inputProps={{
                          maxLength: 10,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {codArg}
                            </InputAdornment>
                          ),
                        }}
                        helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        type="number"
                        focused
                        name="telefono2"
                        label="Telefono 2"
                        color="primary"
                        onChange={almacenar}
                        value={datosformulario.telefono2}
                        inputProps={{
                          maxLength: 10,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {codArg}
                            </InputAdornment>
                          ),
                        }}
                        helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        type="number"
                        focused
                        name="telefono3"
                        label="Telefono 3"
                        color="primary"
                        onChange={almacenar}
                        value={datosformulario.telefono3}
                        inputProps={{
                          maxLength: 10,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {codArg}
                            </InputAdornment>
                          ),
                        }}
                        helperText="Cód área (sin 0) + número sin el 15. Ej:341xxxxxx "
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl size="small" fullWidth focused required>
                        <InputLabel>Producto</InputLabel>
                        <Select
                          name="id_producto"
                          labelId="id_producto"
                          value={datosformulario.id_producto}
                          label="Producto"
                          onChange={almacenar}
                        >
                          {productos
                            ? productos.map((elemento) => (
                                <MenuItem
                                  key={elemento.idproducto}
                                  value={elemento.idproducto}
                                >
                                  {elemento.nombre}
                                </MenuItem>
                              ))
                            : null}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Latitud"
                        focused
                        name="latitud"
                        label="Latitud"
                        color="primary"
                        onChange={almacenar}
                        value={datosformulario.latitud}
                        inputProps={{ maxLength: 45 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PushPinIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Longitud"
                        focused
                        name="longitud"
                        label="Longitud"
                        color="primary"
                        onChange={almacenar}
                        value={datosformulario.longitud}
                        inputProps={{ maxLength: 45 }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PushPinIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {/* HOLA */}
                    <Grid item xs={6} display={"flex"} justifyContent={"end"}>
                      <FormControlLabel
                        labelPlacement="end"
                        label={
                          <Typography variant="button">
                            Precio Especial
                          </Typography>
                        }
                        control={
                          <Switch
                            name="precio_especial"
                            checked={checked}
                            onChange={handleChangeCheck}
                          />
                        }
                      />
                    </Grid>
                    {datosformulario.id_empresa == null ? (
                      <p>Elegir una empresa para cargar precio</p>
                    ) : (
                      checked === true && (
                        <Grid item xs={6} display={'flex'} justifyContent={'start'}>
                          <Button
                            variant="outlined"
                            onClick={() => { setDialog(false); setAlta(true) }}
                            color="primary"
                          >
                            Cargar Precio
                          </Button>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
                {/* <Grid item xs={2}>
                <FormControl size="small" fullWidth focused required>
                  <InputLabel>Producto</InputLabel>
                  <Select
                    name="id_producto"
                    labelId="id_producto"
                    value={datosformulario.id_producto}
                    label="Producto"
                    onChange={almacenar}
                  >
                    {productos
                      ? productos.map((elemento) => (
                          <MenuItem
                            key={elemento.idproducto}
                            value={elemento.idproducto}
                          >
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid> */}
                {/* <Grid item xs={2}>
                <FormControl size="small" fullWidth focused required>
                  <InputLabel>Provincia</InputLabel>
                  <Select
                    name="id_provincia"
                    labelId="id_provincia"
                    value={datosformulario.id_provincia}
                    label="Provincia"
                    onChange={almacenar}
                  >
                    {provincias
                      ? provincias.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.provincia}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl size="small" fullWidth focused required>
                  <InputLabel>Localidad</InputLabel>
                  <Select
                    focused
                    required
                    name="id_localidad"
                    label="Localidad"
                    value={datosformulario.id_localidad}
                    onChange={almacenar}
                  >
                    {localidades
                      ? localidades.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.localidad}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl size="small" fullWidth focused required>
                  <InputLabel>Sucursal</InputLabel>
                  <Select
                    focused
                    required
                    name="id_sucursal"
                    label="Sucursal"
                    value={datosformulario.id_sucursal}
                    onChange={almacenar}
                  >
                    {sucursales
                      ? sucursales.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid> */}
                {/* <Grid item xs={2}>
                <FormControl size="small" fullWidth focused>
                  <InputLabel>Ruta</InputLabel>
                  <Select
                    name="id_ruta"
                    value={datosformulario.id_ruta}
                    label="Ruta"
                    onChange={almacenar}
                  >
                    {rutas
                      ? rutas.map((elemento) => (
                          <MenuItem key={elemento.id} value={elemento.id}>
                            {elemento.nombre}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid> */}
                {/* <Grid item xs={6}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Ejemplo: Casa Central"
                  type="text"
                  required
                  focused
                  name="nombre_direccion"
                  label="Lugar de entrega"
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.nombre_direccion}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BadgeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Direccion de entrega"
                  type="text"
                  required
                  focused
                  name="direccion_entrega"
                  id="direccion_entrega"
                  label="Direccion de entrega"
                  color="primary"
                  onChange={almacenar}
                  value={
                    datosformulario.direccion_entrega &&
                    datosformulario.direccion_entrega.replace(/\b\w/g, (l) =>
                      l.toUpperCase()
                    )
                  }
                  inputProps={{ maxLength: 200 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}
                {/* <Grid item xs={12}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Sr .. "
                  type="text"
                  focused
                  name="contacto"
                  label="Contacto"
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.contacto}
                  inputProps={{ maxLength: 200 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  type="number"
                  focused
                  name="telefono1"
                  label="Telefono 1"
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.telefono1}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 15 }} 
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  type="number"
                  focused
                  name="telefono2"
                  label="Telefono 2"
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.telefono2}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 15 }} 
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  fullWidth
                  type="number"
                  focused
                  name="telefono3"
                  label="Telefono 3"
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.telefono3}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 15 }} 
                />
              </Grid> */}
                {/* <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="ejemplo@mail.com"
                  type="email"
                  focused
                  name="mails"
                  id="mails"
                  label="Mails"
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.mails}
                  inputProps={{ maxLength: 300 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContactMailIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}
                {/* <Grid item xs={2}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Latitud"
                  focused
                  name="latitud"
                  label="Latitud"
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.latitud}
                  inputProps={{ maxLength: 45 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PushPinIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Longitud"
                  focused
                  name="longitud"
                  label="Longitud"
                  color="primary"
                  onChange={almacenar}
                  value={datosformulario.longitud}
                  inputProps={{ maxLength: 45 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PushPinIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> */}
                <Grid item xs={5}>
                  {/* ACA VA EL MAPA  */}
                  <Grid container direction="column">
                    <UbicarDomMapaAlta
                      setDatosDir={setDatosformulario}
                      datosDir={datosformulario}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                mt={2}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  disabled={disBtn}
                  variant="contained"
                  onClick={() => props.setOpen(false)}
                  color="error"
                >
                  Volver
                </Button>
                <Button
                  disabled={
                    disBtn ||
                    !datosformulario.id_provincia ||
                    !datosformulario.id_localidad ||
                    !datosformulario.id_sucursal ||
                    !datosformulario.direccion_entrega ||
                    !datosformulario.nombre_direccion
                  }
                  variant="contained"
                  // type="submit"
                  color="success"
                  onClick={(e) => guardarDireccion(e)}
                >
                  Guardar Direccion
                </Button>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      ) : null}
      {alta ? (
        <AddPrecioAlta
          open={alta}
          onClose={handleClose}
          setOpen={setAlta}
          direccion={direccionProp}
          datos={datosformulario}
        />
      ) : null}
    </div>
  );
}

export default DialogEdicion;
