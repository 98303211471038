import React, { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../../services/apirest";
import {
  Container,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import WhereToVoteTwoToneIcon from "@mui/icons-material/WhereToVoteTwoTone";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Delete, Edit } from "@mui/icons-material";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { createTheme, ThemeProvider, useTheme } from "@mui/material";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
// import PendingActionsIcon from '@mui/icons-material/PendingActions';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

import { MRT_Localization_ES } from "material-react-table/locales/es"; //Tabla en español
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
} from "material-react-table";
import Divider from "@mui/material/Divider";

// //Componentes PROPIOS.
import EditarPedido5 from "./EditarPedido5";
import DetallePedido from "./DetallePedido";
import Title from "../../Title";
import FormBajaPedido2 from "./FormBajaPedido2";
import DialogEntregado from "./DialogEntregado";
import FormPending from "./FormPending";
import ConfirmDialog from "../../../components/ConfirmDialog"
import ModalInfoErrorFacturacion from "./ModalInfoErrorFacturacion"

import { getData, putData } from "../../../services/library"

function TablaPedidos(props) {
  const [pedidos, setPedidos] = useState(null); // Acá me trigo los pedidos que existen
  const sucAuth = localStorage.getItem("suc_auth");

  const handleCloseEdicion = () => {
    setEditar(false);
    setTabla(true);
    props.setShowTabs(true)
  };

  const [tabla, setTabla] = useState(true);
  const [editar, setEditar] = useState(false);
  const [baja, setBaja] = useState();
  const [formEntregado, setFormEntregado] = useState(false);
  const [pedido, setPedido] = useState({});
  const [showFormPending, setShowFormPending] = useState(false);
  const [showDialogMarcarFact, setShowDialogMarcarFact] = useState();

  const [showErrorFacturacion, setShowErrorFacturacion]=useState(false);

  useEffect(() => {
    getGlobalOrders();
  }, [tabla])

  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substring(0, 10);

  const getGlobalOrders = () => {
    switch (props.activeTab) {
      case 0:
        getData("/pedidos/all/" + sucAuth, setPedidos);
        break;

      case 1:
        getData("/pedidos/estado/19/" + sucAuth, setPedidos);
        break;

      case 2:
        getData("/pedidos/estado/21/" + sucAuth, setPedidos);
        break;

      case 3:
        getData("/pedidos/estado/23/" + sucAuth, setPedidos);
        break;

      case 4:
        const estados = [40, 243, 244]; // Estados que quieres consultar // 243 error de facturacion
        const estadosStr = estados.join(",");
        getData("/pedidos/estado/" + estadosStr + "/" + sucAuth, setPedidos);
        break;

      case 5:
        getData("/pedidos/noentregados/" + sucAuth, setPedidos);
        break;

      case 6:
        getData("/pedidos/estado/facturados/" + sucAuth + '/' + date, setPedidos); // aca tengo que poner los facturados 
        // console.log("pedidos", pedidos);
        
        break;

      default:
        console.log("Error");
        break;
    }
  };

  useEffect(() => {
    getGlobalOrders();
  }, [props.activeTab])


  /* PARA MARCAR EL PEDIDO COMO FACTURADO USANDO UN DIALOG GENÉRICO: */
  //NOTIFICACION
  //defino acá la notificacion porque necesito pasarla como parámetro a la función de guardado que está en library
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  //Funcion para marcar pedido como facturado. se toma de library.
  const handlePutData = (e) => {
    putData(e, "/pedidos/" + pedido.id_pedido, {
      id_estado: 25
    }, setNotificacion);
    getData("/pedidos/estado/" + 40 + "/" + sucAuth, setPedidos)
    // console.log("Voy a obtener los pedidos a facturar nuevamente")
  };

  /* FIN DE MARCAR EL PEDIDO COMO FACTURADO*/
  const columns = useMemo(
    () => [
      {
        accessorKey: "id_pedido", //access nested data with dot notation
        header: "N°",
        size: 10,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "cliente", //access nested data with dot notation
        header: "Cliente",
        size: 250,
        enableColumnActions: false,
        showColumnFilters: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <span>
            {row.original.cliente} {row.original.sisven_id ? (`(${row.original.sisven_id})`) : ""}
          </span>
        ),
      },
      {
        accessorKey: "localidad_direccionentrega", //access nested data with dot notation
        header: "Localidad Domicilio",
        size: 140,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "kgacargar", //access nested data with dot notation
        header: "Pedido(KG)",
        size: 90,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "fecha_requerida", //access nested data with dot notation
        header: "Fecha Requerida",
        size: 90,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "sucursal_atiende", //access nested data with dot notation
        header: "Sucursal",
        enableColumnActions: false,
        filterVariant: "select"
      },
      {
        accessorKey: "empresa", //access nested data with dot notation
        header: "Empresa",
        size: 80,
        enableColumnActions: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "valor", //access nested data with dot notation
        header: "Estado",
        size: 70,
        enableColumnActions: false,
        enableColumnFilter: false,
      },

    ],
    []
  );

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    showColumnFilters: false,
    pagination: {
      pageSize: 10,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 16,
      color: "white",
      fontWeight: "normal",
      backgroundColor: "#2471A3",
      pt: 1,
    },
  };

  const globalTheme = useTheme();
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.primary, //primary color for the table
          info: {
            main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "rgb(234, 239, 241)"
                : "#000", //pure black table in dark mode for fun
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "0.8rem", //larger tooltip font size
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const rowBg = {
    19: "#F5CBA7", //Lo voy a poner en amarillo
    21: "#ABEBC6", //aprobado -> verde clarito
    22: "#EC7063", //rechazado -> rojo
    23: "#85C1E9", //asignado -> celestito
    24: "#138D75", //entregado
    25: "#D7BDE2", //Facturado -> violeta
    40: "#D6DBDF",
    41: "#EC7063", //CANCELADO -> BORDÓ
    243: "#f29696" //ERROR DE FACTURACION
  };


  return (
    <div>
      {tabla && pedidos ? (
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            enableFacetedValues
            enableHiding={false}
            enablePinning={false}
            enableDensityToggle={false}
            enableColumnResizing
            enableExpandAll={false}
            columns={columns}
            data={pedidos}
            enableRowActions
            initialState={initialState}
            positionActionsColumn="last"
            muiTableHeadCellProps={tableHeadCellProps}
            localization={MRT_Localization_ES}
            muiTableBodyCellProps={({ row }) => ({
              align: "center",
              sx: {
                backgroundColor: rowBg[row.original.id_estado] ?? "#fff",
                color: "black",
                borderColor: "black",
              },
            })}
            renderDetailPanel={({ row }) => (
              <DetallePedido datos={row.original} />
            )}
            muiSearchTextFieldProps={muiSearchTextFieldProps}
            renderTopToolbarCustomActions={() => <></>}
            renderRowActionMenuItems={({ closeMenu, row }) => [
              <MenuItem
                disabled={
                  row.original.id_estado !== 19 && row.original.id_estado !== 21
                }
                key={0}
                onClick={() => {
                  props.setShowTabs(false);
                  closeMenu();
                  setTabla(false);
                  setPedido(row.original);
                  setEditar(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <BorderColorIcon color="primary" />
                </ListItemIcon>
                Editar
              </MenuItem>,

              <MenuItem
                disabled={
                  row.original.id_estado !== 19 && row.original.id_estado !== 21
                }
                key={1}
                onClick={() => {
                  closeMenu();
                  setPedido(row.original);
                  setBaja(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <Delete color="error" />
                </ListItemIcon>
                Cancelar Pedido
              </MenuItem>,

              <Divider light />,
              <MenuItem
                disabled={row.original.id_estado !== 23}
                key={2}
                onClick={() => {
                  closeMenu();
                  setPedido(row.original);
                  setFormEntregado(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <WhereToVoteTwoToneIcon color="success" />
                </ListItemIcon>
                {/* Marcar Entregado */}
                Registrar Visita
              </MenuItem>,

              <MenuItem
                disabled={
                  !(
                    row.original.id_estado === 41 ||
                    row.original.id_estado === 32
                  )
                }
                key={3}
                onClick={() => {
                  closeMenu();
                  setPedido(row.original);
                  setShowFormPending(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <PendingActionsIcon color="warning" />
                </ListItemIcon>
                Marcar Pendiente
              </MenuItem>,

              <MenuItem
                disabled={!(row.original.id_estado === 40)}
                key={4}
                onClick={() => {
                  closeMenu();
                  setPedido(row.original);
                  setShowDialogMarcarFact(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <AssignmentTurnedInIcon
                    color="warning"
                    sx={{ color: "#BB8FCE" }}
                  />
                </ListItemIcon>
                Marcar Facturado
              </MenuItem>,

              <MenuItem
                disabled={!(row.original.id_estado === 243 || row.original.id_estado === 244)}
                key={4}
                onClick={() => {
                  closeMenu();
                  setPedido(row.original);
                  setShowErrorFacturacion(true)
                  // setShowDialogMarcarFact(true);
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <QueryStatsIcon
                    color="error"
                    // sx={{ color: "#BB8FCE" }}
                  />
                </ListItemIcon>
                Ver error
              </MenuItem>,
            ]}
          />
        </ThemeProvider>
      ) : null}
      <br />
      {editar ? (
        // <>
        <Container maxWidth="lg">
          <Title
            titulo={"Editar Pedido para " + pedido.cliente}
            handleClose={handleCloseEdicion}
          />
          <EditarPedido5
            datos={pedido}
            editar={setEditar}
            tabla={setTabla}
            setShowTabs={props.setShowTabs}
          />
        </Container>
      ) : // </>
      null}
      {baja ? (
        <FormBajaPedido2
          datos={pedido}
          open={baja}
          setOpen={setBaja}
          actualizar={getGlobalOrders}
        />
      ) : null}
      {formEntregado ? (
        <>
          <DialogEntregado
            pedido={pedido}
            open={formEntregado}
            setOpen={setFormEntregado}
            actualizar={getGlobalOrders}
          />
        </>
      ) : null}
      {showFormPending ? (
        <FormPending
          pedido={pedido}
          open={showFormPending}
          setOpen={setShowFormPending}
          actualizar={getGlobalOrders}
        />
      ) : null}
      {showDialogMarcarFact ? (
        <ConfirmDialog
          open={showDialogMarcarFact}
          setOpen={setShowDialogMarcarFact}
          title={"Marcar Pedido Facturado"}
          text={"Confirme que el pedido se ha facturado correctamente."}
          notificacion={notificacion} //Estos parámetros que seteo en la función en "library", los tengo que pasar porque determinan si se mostrará o no la notificación, que está ubicada en "notify"
          setNotificacion={setNotificacion}
          guardar={handlePutData}
        />
      ) : null}
      {
        showErrorFacturacion&&(
          <ModalInfoErrorFacturacion
            open={showErrorFacturacion}
            setOpen={setShowErrorFacturacion}
            datos={pedido}
          />
        )
      }
    </div>
  );
}

export default TablaPedidos