import React from "react";
import { useEffect, useState, useMemo } from "react";
import { UrlApi } from "../../../../../services/apirest";
import Box from "@mui/material/Box";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import MaterialReactTable, {
} from "material-react-table";
import MenuItem from "@mui/material/MenuItem";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Divider from "@mui/material/Divider";
import { Container, ListItemIcon, Grid, Button } from "@mui/material";
import { Delete} from "@mui/icons-material";
import PropaneIcon from "@mui/icons-material/Propane";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import DetalleDir from "./DetalleDir";
import DialogEdicion from "./DialogEdicion";
import DialogBaja from "./DialogBaja";
import DialogAlta from "./DialogAlta"
import DialogAsociarTq from "./DialogAsociarTq"
import TanquesEnDireccion from "./TanquesEnDireccion"
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import {getData} from "../../../../../services/library"
import UpdatedUploadFiles from "../../../../../services/UpdatedUploadFiles"

function DireccionesEntrega(props) {
  const [dataCliente, setDataCliente] = useState(props.cliente);
  const [tabladir, setTabladir] = useState(true); //Estado para mostrar o no la tabla de direcciones de entrega
  const [direcciones, setDirecciones] = useState(null); //Estado para guardar las direcciones de entrega
  const [alta, setAlta] = useState(false);
  const [editar, setEditar] = useState(false);
  const [baja, setBaja] = useState(false);
  const [añadir, setAñadir] = useState(false);
  const [verTanques, setVerTanques] = useState(false);
  const [dialogCarpTec, setDialogCarpTec] = useState(false);
  const [direccion, setDireccion] = useState({});

  const obtenerDirecciones = () => {
    getData("/direccionesentrega/cli/" + dataCliente.id, setDirecciones)
  };

  useEffect(() => {
    obtenerDirecciones();
  }, [alta]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "nombre_direccion", //access nested data with dot notation
        header: "Lugar De Entrega",
        size: 200,
      },
      {
        accessorKey: "direccion_entrega", //access nested data with dot notation
        header: "Dirección",
        size: 200,
      },
      {
        accessorKey: "sucursal",
        header: "Sucursal",
        size: 20,
      },
      {
        accessorKey: "empresa", 
        header: "Empresa",
        size: 20,

      },
      {
        accessorKey: "localidad", 
        header: "Localidad",
        size: 20,
      },
      {
        accessorKey: "precio_especial_tabla", 
        header: "Precio Especial",
        size: 20,
      },
    ],
    []
  );

  const displayColumnDefOptions = {
    "mrt-row-actions": {
      muiTableHeadCellProps: {
        sx: {
          fontSize: 14,
          color: "black",
          fontWeight: "normal",
          paddingTop: 1,
          paddingBottom: 1,
          backgroundColor: "#D4E6F1",
          borderColor: "#FDFEFE",
        },
      },
      size: 60,
    },
  };

  const initialState = {
    density: "compact",
    showGlobalFilter: true,
    pagination: {
      pageSize: direcciones && direcciones.length > 5 ? 10 : 5,
      pageIndex: 0,
    },
  };

  const tableHeadCellProps = {
    align: "center",
    sx: {
      fontSize: 14,
      color: "black",
      fontWeight: "normal",
      paddingTop: 1,
      paddingBottom: 1,
      backgroundColor: "#D4E6F1",
      borderColor: "#FDFEFE",
    },
  };

  const muiSearchTextFieldProps = {
    placeholder: "Buscar..",
    sx: { minWidth: "18rem", backgroundColor: "#ffffff" },
    variant: "outlined",
    color: "primary",
    size: "small",
  };


  return (
    <div>
      {tabladir && direcciones ? (
        <>
          <Container maxWidth="xl" disableGutters>
            {/* <Grid
              container
              mb={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Button
                  onClick={() => setAlta(true)}
                  variant="text"
                  fullWidth
                  startIcon={<AddLocationAltIcon />}
                >
                  Nueva Dirección
                </Button>
              </Grid>
            </Grid> */}
            <Box boxShadow={3}>
              <MaterialReactTable
                muiSearchTextFieldProps={muiSearchTextFieldProps}
                localization={MRT_Localization_ES}
                renderTopToolbarCustomActions={() => (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddLocationAltIcon />}
                      onClick={() => setAlta(true)}
                    >
                      Nueva Dirección
                    </Button>
                  </>
                )}
                columns={columns}
                data={direcciones}
                enableColumnFilterModes={false}
                enableColumnOrdering={false}
                enableGrouping={false}
                enableRowActions
                initialState={initialState}
                enableRowSelection={false}
                positionActionsColumn="last"
                enableHiding={false}
                muiTableHeadCellProps={tableHeadCellProps}
                positionExpandColumn="first"
                renderDetailPanel={({ row }) => (
                  <DetalleDir
                    direccion={row.original}
                    actulizar={obtenerDirecciones}
                    cliente={dataCliente}
                  />
                )}
                enableExpandAll={false}
                muiTableBodyCellProps={({ row }) => ({
                  align: "center",
                  sx: {
                    backgroundColor: "#ffffff",
                    color: "black",
                    borderColor: "#FDFEFE",
                  },
                })}
                enableColumnActions={false}
                enableColumnFilters={false} //Quita filtros por columna
                displayColumnDefOptions={displayColumnDefOptions}
                enableDensityToggle={false}
                renderRowActionMenuItems={({ closeMenu, row }) => [
                  <MenuItem
                    key={0}
                    onClick={() => {
                      closeMenu();
                      setDireccion(row.original);
                      setEditar(true);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <BorderColorIcon color="primary" />
                    </ListItemIcon>
                    Editar
                  </MenuItem>,

                  <MenuItem
                    key={1}
                    onClick={() => {
                      closeMenu();
                      setDireccion(row.original);
                      setBaja(true);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Delete color="error" />
                    </ListItemIcon>
                    Eliminar
                  </MenuItem>,

                  <Divider light />,
                  <MenuItem
                    key={1}
                    onClick={() => {
                      closeMenu();
                      setAñadir(true);
                      setDireccion(row.original);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <PropaneIcon color="success" />
                    </ListItemIcon>
                    Añadir Tanque
                  </MenuItem>,
                  <MenuItem
                    key={1}
                    onClick={() => {
                      closeMenu();
                      setDireccion(row.original);
                      setVerTanques(true);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <MiscellaneousServicesIcon color="secondary" />
                    </ListItemIcon>
                    Administrar Tanques En Domicilio
                  </MenuItem>,
                  <MenuItem
                    key={1}
                    onClick={() => {
                      closeMenu();
                      setDireccion(row.original);
                      setDialogCarpTec(true);
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <FolderSpecialIcon color="primary" />
                    </ListItemIcon>
                    Carpeta Técnica
                  </MenuItem>,
                ]}
              />
            </Box>
            <br />
            <br />
          </Container>
        </>
      ) : null}
      {editar ? (
        <DialogEdicion
          tipoCliente={dataCliente.id_tipocliente}
          direccion={direccion}
          open={editar}
          setOpen={setEditar}
          actualizar={obtenerDirecciones}
        />
      ) : null}
      {baja ? (
        <DialogBaja
          direccion={direccion}
          open={baja}
          setOpen={setBaja}
          actualizar={obtenerDirecciones}
        />
      ) : null}
      {añadir ? (
        <DialogAsociarTq
          direccion={direccion}
          cliente={dataCliente}
          open={añadir}
          setOpen={setAñadir}
        />
      ) : null}
      {verTanques ? (
        <TanquesEnDireccion
          direccion={direccion}
          cliente={dataCliente}
          open={verTanques}
          setOpen={setVerTanques}
          direcciones={direcciones}
        />
      ) : null}
      {alta ? (
        <DialogAlta
          open={alta}
          setOpen={setAlta}
          cliente={dataCliente}
          actualizar={obtenerDirecciones}
        />
      ) : null}
      {dialogCarpTec ? (
        <UpdatedUploadFiles
          fileId={direccion.nro_dir}
          open={dialogCarpTec}
          setOpen={setDialogCarpTec}
          title={"Carpeta Técnica"}
        />
      ) : null}
    </div>
  );
}

export default DireccionesEntrega;
