import React, { useState, useEffect } from "react";
import { getData, putData } from "../../../../../services/library";
import { logEventos } from "../../../../../services/services";
import { Dialog, DialogContent,  FormControl, MenuItem, Select, InputLabel, DialogContentText, Grid, Box, Typography, Button, Alert, AlertTitle } from '@mui/material';
import Notify from "../../../../Notify";

export default function ModalSegmento(props) {
    const [segmentos, setSegmentos] = useState([]);
    const [segSelected, setSegselectd] = useState(props.datos.id_segmento)
    const [notificacion, setNotificacion] = useState({
        open: false,
        mensaje: "",
        severity: "",
        color: "",
    });

    useEffect(() => {
        getData('/segmentos', setSegmentos)
    }, []);

    const updateSegmento = async (e) => {
        putData(e,
            `/clientes/${props.datos.id}`,
            {
                id_segmento: segSelected,
                // id_subsegmento:null,
                usuario: localStorage.getItem('user')
            },
            setNotificacion,
            props.actualizar()
        );        
        logEventos('Cambio de segmento en cliente', 'Addpedido', 'Cliente granel', localStorage.getItem('user'));
    };
  

    return (
        <Dialog maxWidth="md" fullWidth open={props.open} >
            <DialogContent>
                <Alert severity="warning">
                    <AlertTitle>Atención</AlertTitle>
                    El segmento de este cliente es desconocido.-
                </Alert>
                <DialogContentText>
                    <Box p={2}>
                        <Typography variant="caption" display="block" gutterBottom>
                            Es necesario modificarlo para obtener una lista de precios.
                        </Typography>
                    </Box>
                </DialogContentText>
                <form autoComplete="off" onSubmit={updateSegmento}>
                    <FormControl focused required fullWidth size={"small"}>
                        <InputLabel>Segmento</InputLabel>
                        <Select
                            style={{ backgroundColor: "#FFFFFF" }}
                            label="Segmento"
                            name="id_segmento"
                            value={segSelected.id}
                            onChange={(e) => {
                                setSegselectd(e.target.value)
                            }}
                        >
                            {segmentos
                                ? segmentos.map((elemento) => (
                                    <MenuItem key={elemento.id} value={elemento.id}>
                                        {elemento.segmento} - {elemento.codigo}
                                    </MenuItem>
                                ))
                                : null}
                        </Select>
                    </FormControl>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-end"
                        mt={2}
                    >
                        <Button variant="contained"
                            onClick={() => props.onClose()}
                            color="error">
                            Volver
                        </Button>

                        <Button
                            variant="contained"
                            type="submit"
                            color={"success"}
                        >
                            Actualizar
                        </Button>
                    </Grid>
                </form>
            </DialogContent>
            {notificacion && notificacion.open ? (
                <Notify
                    mensaje={notificacion.mensaje}
                    open={notificacion.open}
                    color={notificacion.color}
                    handleClose={props.onClose}
                    severity={notificacion.severity}
                />
            ) : null}
        </Dialog>

    )



}