
import React, { useState, useEffect } from "react";
import { putData, postData } from "../../../../services/library";
import {
  Container, Typography, Grid, Box, TextField, Button, Stack,
  DialogContent, Dialog, DialogTitle, Alert, AlertTitle,
  DialogContentText
} from "@mui/material";
import { logEventos } from "../../../../services/services";
import Notify from "../../../Notify";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import moment from "moment";

export default function AddPrecioEspecialDireccion(props) {
  const [datoslista, setDatoslista] = useState({
    uuid_direccionentrega: "",
    lt_con_iva: "",
    lt_sin_iva: "",
    kg_con_iva: "",
    kg_sin_iva: "",
    vigencia: ""
  });
  const [disabledGuardar, setDisabledGuardar] = useState(false);
  const [notificacion, setNotificacion] = useState({
    open: false,
    mensaje: "",
    severity: "",
    color: "",
  });

  //**********************************************************************************************************************************//
  const style = {
    backgroundColor: "#ffffff",
    width: "100%",
  };
  const size = "small";

  const handleClose = () => {
    props.alta(false);
    props.tabla && props.tabla(true);
  };
  //*************************** GUARDAR *******************************************************************************************************//
  const guardarPrecio = async (e) => {
    setDisabledGuardar(false);
    postData(e, '/precios_especiales',
      {
        uuid_direccionentrega: props.direccion.nro_dir,
        lt_con_iva: datoslista.lt_con_iva,
        lt_sin_iva: datoslista.lt_sin_iva,
        kg_con_iva: datoslista.kg_con_iva,
        kg_sin_iva: datoslista.kg_sin_iva,
        vigencia: datoslista.vigencia,
        usuario: localStorage.getItem("user"),
      },
      setNotificacion
    )
    logEventos("Guardar nuevo precio especial", "Precio especiales", "referencias tabla", localStorage.getItem('user'));
    props.onClose(false)
  };

  const almacenar = (event) => {
    //a medida que voy llenando los datos, hago directamente el calculo 

    const { name, value } = event.target;
    let newValue = { ...datoslista, [name]: value };

    //entonces en cada campo hago lo siguiente mi reynis
    //depende en el cmampo que estoy hago el calculo con el valor del porcentaje de iva del producto
    // que obtengo de la direccion de entrega seleccionada ja ja ja y  ademas con la densidad base del prducto lleno todo porque soy muy inteligente 

    const densidad = props.direccion.id_empresa === 1 
    ? props.direccion.densidad_hipergas 
    : props.direccion.densidad_autogas;

    if (name === "lt_sin_iva") {
      const precioConIva = parseFloat(value) * props.direccion.valor
      newValue.lt_con_iva = precioConIva.toFixed(4);
      newValue.kg_con_iva = (precioConIva / densidad).toFixed(4);
      newValue.kg_sin_iva = (newValue.kg_con_iva / props.direccion.valor).toFixed(4)
    }

    if (name === "kg_sin_iva") {
      const precioConIva = parseFloat(value) * props.direccion.valor
      newValue.kg_con_iva = precioConIva.toFixed(4);
      newValue.lt_con_iva = (precioConIva * densidad).toFixed(4);
      newValue.lt_sin_iva = (newValue.lt_con_iva / props.direccion.valor).toFixed(4);
    }

    if (name === "kg_con_iva") {
      const precioConIva = parseFloat(value) / props.direccion.valor
      newValue.kg_sin_iva = precioConIva.toFixed(4);
      newValue.lt_con_iva = (precioConIva / densidad).toFixed(4);
      newValue.lt_sin_iva = (newValue.lt_con_iva / props.direccion.valor).toFixed(4);
    }

    if (name === "lt_con_iva") {
      const precioConIva = parseFloat(value) / props.direccion.valor
      newValue.lt_sin_iva = precioConIva.toFixed(4);
      newValue.kg_sin_iva = (precioConIva * densidad).toFixed(4);
      newValue.kg_con_iva = (newValue.kg_sin_iva * props.direccion.valor).toFixed(4);
    };

    setDatoslista(newValue)
  };

  //*************************** HABILITAR Y DESHABILITAR BOTON GUARDAR ***************************************************************************************//
  useEffect(() => {
    if (datoslista.lt_con_iva === "" || datoslista.lt_sin_iva === "" || datoslista.kg_con_iva === "" || datoslista.kg_sin_iva === "" || datoslista.vigencia === "") {
      setDisabledGuardar(true);
    } else {
      setDisabledGuardar(false);
    }
  }, [datoslista]);

  const today = new Date().toISOString().split("T")[0]; // Formato YYYY-MM-DD


  return (
    <div>
      <Dialog
        maxWidth="xl"
        open={props.open}
        onClose={() => props.onClose(false)}
        style={{ zIndex: 1 }}
      >
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>Atención</AlertTitle>
            No hay una lista de precios registrada para esta dirección
          </Alert>
          <br/>
          <DialogContentText>
            A continuación puede registrar una nueva
          </DialogContentText>
          <br/>
          <form onSubmit={guardarPrecio} autoComplete="off">

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              

              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size={size}
                  style={style}
                  focused
                  color="primary"
                  required
                  name="lt_sin_iva"
                  label="Precio litro sin IVA"
                  variant="outlined"
                  value={datoslista.lt_sin_iva}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size={size}
                  style={style}
                  focused
                  color="primary"
                  required
                  name="lt_con_iva"
                  label="Precio litro con IVA"
                  variant="outlined"
                  value={datoslista.lt_con_iva}
                  onChange={almacenar}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size={size}
                  style={style}
                  focused
                  color="primary"
                  required
                  name="kg_sin_iva"
                  label="Precio kilo sin IVA"
                  variant="outlined"
                  value={datoslista.kg_sin_iva}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size={size}
                  style={style}
                  focused
                  color="primary"
                  required
                  name="kg_con_iva"
                  label="Precio kilo con IVA"
                  variant="outlined"
                  value={datoslista.kg_con_iva}
                  onChange={almacenar}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  fullWidth
                  size={size}
                  style={style}
                  focused
                  color="primary"
                  required
                  type="date"
                  name="vigencia"
                  label="Vigencia"
                  variant="outlined"
                  value={datoslista.vigencia && moment(datoslista.vigencia).format("YYYY-MM-DD")}
                  onChange={almacenar}
                  InputProps={{
                    inputProps: {
                        min: today
                    }
                }}
                />
              </Grid>
            </Grid>
            {/* </>
                  )}
                </Grid> */}
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
              mt={2}
            >
              <Button variant="contained" onClick={() => props.onClose(false)} color="error">
                Volver
              </Button>

              <Button
                variant="contained"
                type="submit"
                color="success"
                disabled={disabledGuardar}
              >
                Guardar Precio
              </Button>
            </Grid>

          </form>
          {notificacion && notificacion.open ? (
            <Notify
              mensaje={notificacion.mensaje}
              open={notificacion.open}
              color={notificacion.color}
              handleClose={props.onClose}
              severity={notificacion.severity}
            />
          ) : null}

        </DialogContent>
      </Dialog>
    </div>
  )
}

//----- Component --------------------------------------------------------/
// Developer :  Daiana Curcio / Nicolas pascucci
// Creation Date :26/06/2024
// Version : 1
// Description : Agregar precios
//------Changes ----------------------------------------------------------//
// Change Date :15/8
// Change by :Candela Grosso
// Change description: Acomode el titulo y saque los console.log
// Change Date :
// Change by :
// Change description:
//========================================================================/